/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"

import "../styles/global.scss"

const Layout = ({ pageTitle, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} pageTitle={pageTitle} />
      <main className="section">
        <div className="container">
          {children}
        </div>
      </main>
      <footer style={{ display: `flex`, alignItems: `center`, flexFlow: `column`, padding: `20px 0`, backgroundColor: `#fcfcfc` }}>
        <div>
          <ul className="footer-menu">
            <li>
              <a href="https://github.com/web247">Github</a>
            </li>
            <li>
              <a href="https://drupal.org/u/web247">Drupal</a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/adrian-ababei">Linkedin</a>
            </li>
          </ul>
        </div>
        <hr />
        <div>© {new Date().getFullYear()}, Adrian Ababei</div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
