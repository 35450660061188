// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import HeaderBackground from "./header/header-background";

const Header = ({ siteTitle, pageTitle }) => (

  <HeaderBackground siteTitle={siteTitle} pageTitle={pageTitle} />

  // <header
  //   style={{
  //     marginBottom: `1.45rem`,
  //   }}
  // >
  //   <div className="container is-fluid is-marginless">
  //     <h1 style={{ margin: 0 }}>
  //       <Link
  //         to="/"
  //         style={{
  //           color: `black`,
  //           textDecoration: `none`,
  //         }}
  //       >
  //         {siteTitle}
  //       </Link>
  //     </h1>


  //   </div>
  // </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
