import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import "./header-background.scss"

const HeaderBackground = ({ siteTitle, pageTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      nodeConfigPage {
        id
        title
        field_home_header_text
        relationships {
          field_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 75) {
                    ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  const openMenu = function ($event) {
    $event.preventDefault();
    const el = $event.target;
    if (el) {
      const target = el.dataset.target;
      const $target = document.getElementById(target);
      el.classList.toggle('is-active');
      if ($target) {
        $target.classList.toggle('is-active');
      }
    }

  }

  return <div>
    <nav className="navbar is-fixed-top is-light anav" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <Link to="/" className="navbar-item intro">
          {siteTitle}
        </Link>
        <a onClick={openMenu} href="/" role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="siteNavbar">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div id="siteNavbar" className="navbar-menu">
        <div className="navbar-start">
        </div>
        <div className="navbar-end">
          <Link activeClassName="is-active" to="/" className="navbar-item">Home</Link>
          <Link partiallyActive={true} activeClassName="is-active" to="/portfolio" className="navbar-item">Portfolio</Link>
          <Link partiallyActive={true} activeClassName="is-active" to="/testimonials" className="navbar-item">Testimonials</Link>
          <Link partiallyActive={true} activeClassName="is-active" to="/blog" className="navbar-item">Blog</Link>
          <Link partiallyActive={true} activeClassName="is-active" to="/contact" className="navbar-item">Contact</Link>
        </div>
      </div>
    </nav>
    <BackgroundImage
      Tag="header"
      fluid={data.nodeConfigPage.relationships.field_image.localFile.childImageSharp.fluid}
      backgroundColor={`#040e18`}
      style={{
        marginBottom: `1.45rem`,
        minHeight: `450px`,
        display: `flex`,
        flexFlow: `column`,
        justifyContent: `center`
      }}>
      <section className="section" style={{ display: `flex`, height: `100%` }}>
        <div className="container is-widescreen">
          <div style={{ display: `flex`, justifyContent: `center`, alignItems: `center`, height: `100%` }}>
            <div style={{ color: `#fff`, fontSize: `3rem`, fontWeight: `800` }}>
              {pageTitle}
            </div>
          </div>
        </div>
      </section>
    </BackgroundImage>
  </div>
}

export default HeaderBackground
