import React from "react"
import { Link } from "gatsby"

const BreadCrumbs = ({ input }) => {
  return (
    <nav className="breadcrumb" aria-label="breadcrumbs" style={{whiteSpace: `pre-wrap`}}>
      <ul>
        {
          Object.keys(input).map(node => {
            return (
              input[node] ? <li key={node}>
                <Link to={input[node]}>{node}</Link>
                </li> : <li key={node} className="is-active">
                  <span style={{ paddingLeft: `0.75rem` }}>{node}</span>
                </li>
            )
          })
        }
      </ul>
    </nav>
  )
}

export default BreadCrumbs
